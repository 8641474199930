

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/accusation-criminelle-possession-drogues-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost7 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Accusations de possession et trafic de drogues : trouvez un avocat en droit criminel RAPIDEMENT! - Soumissions Avocat"
        description="On vous accuse de possession ou de trafic de drogues? Consultez un avocat spécialisé en droit criminel DÈS MAINTENANT avec Soumissions Avocat!"
        image={LeadImage}>
        <h1>Accusations de possession et trafic de drogues : trouvez un avocat en droit criminel RAPIDEMENT!</h1>

					
					
								
						
							
							
						
							
					
			
							
						
				
					
			<h4>SOMMAIRE</h4>					
		
			
				
			
		
				
				
						
					
		
								
					
		
				
						
							
					
			
							
						
				
								
					<p></p>
<p><strong>La consommation de cannabis a peut-être été légalisée en 2018, mais les crimes reliés aux autres drogues demeurent bien présents!</strong> Qu’il s’agisse de possession de stupéfiants, de trafic ou d’exportation, les conséquences de ces crimes sont graves et laissent des marques indélébiles sur le casier judiciaire d’une personne reconnue coupable.  </p>
<p><StaticImage alt="accusation criminelle possession drogues" src="../images/accusation-criminelle-possession-drogues-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>On vous accuse d’une infraction de possession en vue d’en faire le trafic? Vous avez été arrêté et accusé de possession criminelle de stupéfiants? Le temps presse pour trouver un professionnel du droit qui viendra à votre défense, car ces accusations sont extrêmement sérieuses et graves.</p>
<p><strong>Soumissions Avocat vous explique tout ce que vous devez savoir sur les différentes infractions reliées aux drogues et sur les peines que vous risquez de recevoir. Plus important encore, nous pouvons trouver l’avocat pour vous défendre, il suffit de nous contacter!</strong></p><p><strong></strong></p>
<p></p>
<h2>Quelles drogues sont illégales au Canada et quelles sont les principales infractions?</h2>
<p>Au Canada et au Québec, le type de drogue qui se retrouve au centre d’une accusation aura un impact considérable sur la gravité de la peine. Comme les différentes drogues présentent des degrés variables de dangerosité, la loi les classe en 6 annexes différentes. Celles-ci auront un impact au moment de la mise en accusation d’un individu pour un crime relié aux drogues.</p>
<p><strong>Voici les principales catégories de drogues illégales au Canada classées selon leurs caractéristiques et leur dangerosité!</strong></p>
<p></p>
<table>
<tbody>
<tr>
<td>
<p><strong> </strong><strong>Annexe I</strong></p>
<p></p>
</td>
<td>Cocaïne, Kétamine, Codéine, Morphine, Héroïne, Opium, PCP.</td>
</tr>
<tr>
<td><strong>Annexe II</strong><strong> </strong></td>
<td>
<p>Cannabis et les différents dérivés comme le Hasch formaient autrefois cette catégorie.</p>
<p></p>
<p>(Depuis 2018, c’est la Loi sur le cannabis qui régit ces substances).</p>
<p></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe III</strong></p>
<p><strong> </strong></p>
</td>
<td>
<p>Ecstasy, Mescaline, Champignons magiques, etc.</p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe IV</strong></p>
<p><strong> </strong></p>
</td>
<td>
<p>Stéroïdes anabolisants, valium, librium, barbituriques.</p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe V</strong></p>
<p><strong> </strong></p>
</td>
<td>
<p>Le Propylhexédrine qui est utilisé pour fabriquer d’autres drogues.</p>
<p></p>
</td>
</tr>
<tr>
<td><strong>Annexe VI</strong></td>
<td>
<p>Éphédrine et pseudoéphédrine utilisées pour fabriquer d’autres drogues.</p>
<p></p>
</td>
</tr>
</tbody>
</table>
<p>Ces substances illégales doivent toutefois se rattacher à une action prohibée par le <strong>Code criminel ou la Loi réglementant certaines drogues et autres substances</strong> afin de fonder une mise en accusation criminelle. On y retrouve quatre infractions principales relatives aux drogues, soit :</p>
<p>-Possession en vue d’en faire le trafic</p>
<p>-Accusation criminelle de possession de drogue</p>
<p>-Production de drogues</p>
<p>-Importation et exportation de drogues<strong>Soumissions Avocat vous explique en détail les éléments constitutifs de chacune de ces infractions et vous présente les peines que vous risquez de recevoir si on vous trouve coupable.</strong></p><p><strong></strong></p>
<p></p>
<h2><strong>Possession en vue d’en faire le trafic : des conséquences SÉVÈRES!</strong></h2>
<p>Le trafic de drogues est considéré comme un fléau sociétal et c’est pourquoi il s’agit d’une infraction sévèrement punie par le Code criminel. Même si le titre de l’infraction est clair, les conditions qui doivent être réunies pour fonder le crime de possession en vue d’en faire le trafic sont très pointues.</p>
<p><strong>Quels éléments doivent être réunis pour constituer une infraction de possession en vue d’en faire le trafic?</strong></p>
<p><strong><StaticImage alt="emprisonnement crimes drogues avocat" src="../images/emprisonnement-crimes-drogues-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></strong></p>
<p><strong>1) La possession personnelle d’une substance illégale: </strong>Il s’agit du premier élément constitutif du crime en question. Avoir la possession d’une substance peut se faire de plusieurs façons.</p>
<p><strong>La possession personnelle. </strong>Une possession sera qualifiée comme telle lorsqu’une personne a la garde physique de la drogue en question et qu’elle en connaît la nature. La très grande majorité des infractions en vue de faire le trafic impliquent des possessions personnelles.</p>
<p><strong>La possession imputée. </strong>Une possession en vue d’en faire le trafic pourra également se faire de façon imputée lorsque l’accusé n’avait pas la garde matérielle de la substance, mais qu’il la conserve dans un lieu lui appartenant pour son usage ou pour celui d’une tierce personne. Une possession pourra également être commune selon la désignation prévue par la loi.</p>
<p><strong>2) L’intention d’en faire le trafic. </strong>L’accusation de possession en vue de trafic est un crime d’intention spécifique, ce qui signifie que l’accusé doit connaître la nature de la substance qu’il possède (savoir qu’il s’agit d’une drogue illégale), en plus d’avoir l’intention subjective d’en faire la vente, le don, la cession, le transport, la livraison ou une autre opération similaire.</p>
<p></p>
<p><strong>Quelles sont les peines prévues pour une accusation de possession en vue d’en faire le trafic? </strong>Tel que mentionné d’entrée de jeu, la type de crime a une incidence sur la gravité de la peine, mais la nature de la substance impliquée est tout aussi importante.</p>
<p><strong>La peine rattachée à l’infraction de possession en vue de trafic variera selon les facteurs suivants!</strong></p>
<p></p>
<table>
<tbody>
<tr>
<td>
<p><strong>Type de drogues concernées par l’accusation</strong></p>
</td>
<td>
<p><strong> </strong></p>
<p><strong>Peine prévue par la Loi réglementant certaines drogues et autres substances</strong></p>
<p><strong> </strong></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong><strong>Annexe I</strong></p>
<p>(Opium, morphine, héroïne, etc.)</p>
<p><strong> </strong></p>
</td>
<td>
<p>Peine minimale de 1 an</p>
<p>Peine maximale d’emprisonnement à perpétuité</p>
<p><strong> </strong></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe III</strong></p>
<p><strong> </strong></p>
<p>(Mescaline, ecstasy, champignons magiques, etc.)</p>
</td>
<td>
<p><strong> </strong></p>
<p><strong> </strong></p>
<p>Peine de 18 mois si l’accusation est par voie sommaire OU amende de 5000$</p>
<p></p>
<p>Peine d’emprisonnement maximale de 10 ans si l’accusation est par voie criminelle</p>
<p><strong> </strong></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe IV</strong></p>
<p><strong></strong>(Stéroïdes anabolisants, valium, librium, etc.)</p>
<p><strong> </strong></p>
</td>
<td>
<p><strong> </strong></p>
<p>Peine de 1 an si l’infraction est par voie sommaire</p>
<p></p>
<p>Peine maximale de 3 ans si l’accusation est par voie criminelle</p>
<p><strong> </strong></p>
</td>
</tr>
</tbody>
</table>
<p><strong>Qu’est-ce qui détermine si l’accusation est portée par voie sommaire ou criminelle? </strong>C’est le Procureur aux poursuites criminelles et pénales qui décide s’il est opportun de procéder pas mise en accusation sommaire ou criminelle, selon sa discrétion.</p>
<p>Lorsqu’une infraction est hybride, comme c’est le cas de l’accusation pour possession en vue d’en faire le trafic, le procureur exerce son pouvoir discrétionnaire en se basant sur la gravité de l’infraction, l’historique criminel de l’accusé et les lignes directrices du DPCP.</p>
<p>Il est nettement plus favorable pour les accusés de procéder par voie de mise en accusation sommaire plutôt que criminel, autant au niveau de la sentence plus clémente que du processus judiciaire allégé.</p>
<p><strong>Un bon avocat criminaliste tentera de faire pencher la balance en faveur d’un tel dénouement.</strong></p><p><strong></strong></p>
<p></p>
<h2>Qu’est-ce qu’une accusation criminelle de possession de stupéfiants?</h2>
<p><strong>Quels sont les éléments constitutifs d’une infraction reliée à la possession criminelle de stupéfiants? </strong>Le crime de possession est prévu à l’article 4 de la Loi réglementant certaines drogues et autres substances. On y prévoit à ce même article qu’il est interdit pour quiconque de se retrouver en possession d’une substance prévue aux annexes 1 et 3 de cette même loi.</p>
<p>La poursuite devra prouver que l’accusé était en possession d’une substance illicite, qu’il avait connaissance de la nature de celle-ci et qu’il en avait le contrôle. En ce qui concerne la quantité, la loi ne précise pas de chiffre précis, mais stipule qu’elle doit être suffisante afin d’être mesurable.</p>
<p><strong>Quelles sont les peines qui vous seront imposées si vous êtes reconnu coupable de possession de stupéfiants? Voici les principales peines prévues par la loi!</strong></p>
<table>
<tbody>
<tr>
<td>
<p><strong>Type de drogue</strong></p>
<p></p>
</td>
<td>
<p><strong>Peine prévue par la Loi réglementant certaines drogues et autres substances</strong></p>
<p></p>
</td>
</tr>
<tr>
<td>
<p><strong>Annexe I</strong></p>
<p></p>
<p>(Opium, morphine, héroïne, etc.)</p>
<p></p>
</td>
<td>
<p><strong>Première offense </strong></p>
<p>(6 mois de prison et/ou amende de 1000$)</p>
<p><strong>Seconde offense </strong></p>
<p>(1 an de prison et/ou amende de 2000$)<strong></strong>Peine maximale d’emprisonnement de 7 ans</p>
</td>
</tr>
<tr>
<td>
<p><strong>Annexe III</strong></p>
<p>(Mescaline, ecstasy, champignons magiques, etc.)</p>
<p></p>
</td>
<td>
<p><strong>Première offense </strong></p>
<p>(6 mois de prison et/ou amende de 1000$)</p>
<p><strong>Seconde offense </strong></p>
<p>(1 an de prison et/ou amende de 2000$)</p>
<p></p>
<p>Peine maximale d’emprisonnement de 3 ans</p>
<p></p>
</td>
</tr>
</tbody>
</table>
<p><strong>Pouvez-vous invoquer une défense pour vous défaire d’une accusation de possession de stupéfiants?  </strong>L’erreur de fait peut être invoquée, au même titre que l’absence de connaissance de la nature de la substance. Comme il s’agit ici d’une mens rea (esprit coupable) de connaissance, un accusé qui croyait transporter du sucre en poudre ne pourra pas être condamné pour possession de cocaïne.</p>
<p>Rappelez-vous qu’en droit criminel, quelle que soit l’infraction qui vous est reprochée, c’est toujours le procureur de la poursuite qui assume le fardeau de preuve, et ce, hors de tout doute raisonnable.</p>
<p><strong>De plus, advenant que vous ne soyez reconnu coupable d’une première offense, la sentence applicable pour l’infraction de possession penchera fort probablement vers la clémence.</strong></p><p><strong></strong></p>
<p></p>
<h2>Pourriez-vous être accusé de production de drogues?</h2>
<p>Le crime de production de drogues est nettement moins fréquent que ceux de possession et de trafic, mais les conséquences qui s’y rattachent sont d’autant plus sévères. En effet, c’est l’article 7 de la Loi réglementant certaines drogues et autres substances qui décrit ce crime et qui stipule que toute fabrication, altération, synthèse ou modification d’une substance à des fins de créer une drogue est interdit.</p>
<p><strong>Si la poursuite parvient à s’acquitter de son lourd fardeau de preuve et à démontrer hors de tout doute raisonnable que vous avez produit des substances interdites, les peines suivantes seront applicables!</strong></p>
<table>
<tbody>
<tr>
<td>
<p><strong> </strong></p>
<p><strong> </strong></p>
<p><strong> </strong></p>
</td>
<td>
<p><strong> </strong></p>
<p><strong>Infraction par voie sommaire </strong></p>
<p><strong> </strong></p>
</td>
<td>
<p><strong> </strong></p>
<p><strong>Infraction par voie criminelle</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Annexe I </strong></p>
<p>(Cocaïne, Opium, morphine, héroïne, etc.)</p>
<p></p>
</td>
<td>
<p>Non-applicable</p>
</td>
<td>
<p><strong>Peine minimale de 2 ans</strong></p>
<p><strong>Peine minimale de 3 ans si : </strong></p>
<p></p>
<p>-La production a mis en danger des personnes mineures</p>
<p></p>
<p>-L’immeuble a produit la drogue dans l’immeuble d’autrui</p>
<p>-La production a mis en danger la vie ou la sécurité publique ou d’autrui</p>
<p></p>
<p><strong>Possibilité d’emprisonnement à perpétuité</strong></p>
<p></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe III</strong></p>
<p>(Mescaline, ecstasy, champignons magiques, etc.)</p>
<p></p>
</td>
<td>
<p>Peine d’emprisonnement maximale de 18 mois</p>
<p>OU</p>
<p></p>
<p>Amende maximale de 5000$</p>
</td>
<td>
<p>Peine maximale d’emprisonnement de 10 ans</p>
</td>
</tr>
<tr>
<td>
<p><strong>Annexe IV</strong></p>
<p>(Stéroïdes anabolisants, valium, librium, etc.)</p>
<p></p>
</td>
<td>
<p>Peine maximale d’emprisonnement de 1 an</p>
<p></p>
<p>OU</p>
<p></p>
<p>Amende maximale de 5000$</p>
<p></p>
</td>
<td>
<p>Peine maximale d’emprisonnement de 3 ans</p>
</td>
</tr>
</tbody>
</table>
<p>Encore une fois, l’infraction de production de drogue est une infraction hybride, à l’exception des substances visées à l’annexe I qui obligent le DPCP à procéder par voie criminelle en raison de la dangerosité des drogues visées. D’ailleurs, la production d’une drogue visée à l’annexe I peut entraîner des peines sévères si les circonstances aggravantes décrites dans la loi sont réunies.</p>
<p><strong>Pour les infractions reliées aux annexes II et IV, la discrétion du procureur entrera en jeu afin de déterminer s’il est dans l’intérêt de la justice de procéder par mise en accusation sommaire ou criminelle.</strong></p><p><strong></strong></p>
<p></p>
<h2>Qu’est-ce qu’un crime d’importation et exportation de drogues?</h2>
<p>L’importation de drogues consiste à faire entrer des substances illicites au Canada, alors que l’exportation consiste plutôt tenter d’en transporter vers un autre pays. Dans un cas comme dans l’autre, il n’est pas nécessaire que l’accusé ait été en possession des substances au moment ou celles-ci ont été retrouvées. Il suffit qu’il soit l’auteur de l’import ou de l’export.</p>
<p><strong>De plus, les peines applicables dans un cas comme dans l’autre sont les mêmes et varieront encore une fois selon la substance et la méthode de mise en accusation!</strong></p>
<p></p>
<table>
<tbody>
<tr>
<td>
<p><strong>Type de drogues</strong></p>
<p></p>
</td>
<td>
<p>Peine pour importation et exportation</p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexe II ou II</strong></p>
<p></p>
</td>
<td>
<p>Peine maximale d’emprisonnement à perpétuité</p>
<p></p>
</td>
</tr>
<tr>
<td>
<p><strong>Annexes III ou VI</strong></p>
<p></p>
</td>
<td>
<p>Peine maximale de 10 ans</p>
<p>OU</p>
<p>Infraction sommaire avec emprisonnement de 18 mois</p>
<p></p>
</td>
</tr>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Annexes IV et V</strong></p>
<p></p>
</td>
<td>
<p>Peine maximale de 3 ans</p>
<p>OU</p>
<p>Infraction sommaire avec emprisonnement de 1 an</p>
</td>
</tr>
</tbody>
</table>
<p><strong>Faites toutefois bien ATTENTION!</strong> Ces peines sont celles applicables si vous avez été arrêté et accusé par les autorités Canadiennes. L’importation et l’exportation de drogues peuvent mettre en jeu la juridiction d’autres pays, surtout si vous vous faites arrêter en sol étranger. Les peines des autres pays pourraient s’avérer moins nettement moins clémentes que celles retrouvées auprès de la justice canadienne.</p><p></p>
<p></p>
<h2><strong>Résumé des peines MAXIMALES et MINIMALES que vous risquez!</strong></h2>
<p>Vous avez toujours de la difficulté à saisir la gravité d’une accusation relative à la possession, le trafic ou la production de drogues? Vous ne voyez toujours pas la raison pourtant évidente <strong>d’engager un avocat dès MAINTENANT?</strong> Pour vous convaincre une bonne fois pour toutes d’agir, Soumissions Avocat vous présente un tableau résumé des peines que vous pourriez recevoir pour une infraction en lien avec les drogues illicites.</p>
<table>
<tbody>
<tr>
<td>
<p><strong> </strong></p>
<p><strong>Type d’infraction</strong></p>
<p><strong> </strong></p>
</td>
<td>
<p><strong> </strong></p>
<p><strong>Peine minimale d’emprisonnement</strong></p>
<p><strong> </strong></p>
</td>
<td>
<p><strong> </strong></p>
<p><strong>Peine maximale d’emprisonnement</strong></p>
</td>
</tr>
<tr>
<td>
<p>-Possession en vue d’en faire le trafic</p>
<p></p>
</td>
<td>
<p>1 an</p>
</td>
<td>
<p>Emprisonnement à perpétuité</p>
</td>
</tr>
<tr>
<td>
<p>-Accusation criminelle de possession de drogue</p>
<p></p>
</td>
<td>
<p>6 mois</p>
</td>
<td>
<p>7 ans</p>
</td>
</tr>
<tr>
<td>
<p>-Production de drogues</p>
<p></p>
</td>
<td>
<p>1 an</p>
<p></p>
</td>
<td>
<p>Emprisonnement à perpétuité</p>
<p></p>
</td>
</tr>
<tr>
<td>
<p>-Importation et exportation de drogues</p>
<p></p>
</td>
<td>
<p>18 mois</p>
</td>
<td>
<p>Emprisonnement à perpétuité</p>
<p></p>
</td>
</tr>
</tbody>
</table>
<p><strong>Voir le nombre d’années que vous pourriez passer derrière les barreaux a de quoi vous motiver à agir n’est-ce pas?</strong> Cessez de vous en faire, vous avec cogné à la bonne porter pour vous faire référer un avocat compétent en droit criminel!</p><p></p>
<p></p>
<h2>Comment un juge déterminera-t-il votre sentence pour une infraction reliée aux drogues?</h2>
<p>En droit criminel, il arrive qu’un accusé n’ait tout simplement aucune carte à jouer pour se défendre contre des accusations. Il en est ainsi lorsque toutes les preuves sont réunies et convaincantes hors de tout doute raisonnable. Lorsqu’un verdict de culpabilité est rendu dans le cadre d’une infraction reliée aux drogues, comment un juge décidera-t-il de la sentence appropriée?</p>
<p></p>
<p><StaticImage alt="peine prison vente trafic drogues" src="../images/peine-prison-vente-trafic-drogues.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p></p>
<p>Lorsqu’une peine minimale est prévue au Code criminel ou dans la Loi, le juge n’a aucune discrétion. Il en est ainsi pour plusieurs infractions relatives aux drogues, mais certaines d’entre elles permettent l’exercice d’une certaine discrétion.</p>
<p>Lorsque le juge a une marge de manœuvre,<strong> la peine déterminée doit être équitable et proportionnelle. </strong>Plusieurs considérations entreront en ligne de compte comme le degré de participation de l’accusé, la gravité du crime ainsi que les droits de la victime. De plus, le principe de l’harmonisation doit être respecté. Celui-ci signifie que des crimes similaires doivent recevoir une sentence similaire.</p>
<p><strong>Les circonstances aggravantes et atténuantes doivent également être prises en compte.</strong> Les caractéristiques propres au contrevenant doivent entrer en ligne de compte. L’âge, les antécédents, le caractère haineux du crime et autres considérations similaires auront un poids dans la balance du juge.</p>
<p><strong>L’emprisonnement est vu comme un dernier ressort!</strong> Le coût social rattaché à l’emprisonnement, autant sur le plan financier que moral, est très important. C’est pourquoi les tribunaux considèrent cette sentence comme un châtiment de dernier recours. Ne vous y méprenez toutefois pas sur ce principe; les juges ordonneront l’emprisonnement si nécessaire, mais devraient considérer les alternatives possibles.</p>
<p><strong>Une bonne partie de la détermination de la peine pèsera sur les épaules de votre avocat qui aura pour rôle de plaider en faveur d’une peine clémente en votre faveur. Il s’agit d’une excellente raison pour choisir un avocat qualifié en droit criminel!</strong></p><p><strong></strong></p>
<p></p>
<h2>Est-il possible de vous DÉBARRASSER d’une accusation criminelle relative aux drogues?</h2>
<p>Une telle accusation ne disparaît pas du jour au lendemain, surtout si le procureur des poursuites criminelles et pénales a une solide preuve contre vous. Cependant, il est possible d’opter pour une stratégie qui allègera votre peine.</p>
<p><strong>Plaider coupable éviter le procès criminel. </strong>Lorsque la preuve du DPCP est accablante, il devient extrêmement difficile de prouver l’innocence de l’accusé. Avant de plaider coupable, il est fréquent qu’un accusé tente d’échanger son plaidoyer de culpabilité pour une peine d’emprisonnement réduite.</p>
<p><strong>Négocier une peine plus clémente, ça se fait! </strong>Le procureur des poursuites criminelles et pénales possède un certain pouvoir de négociation, mais il doit tout de même respecter certaines contraintes. Il doit notamment opter pour une mise en accusation par voie sommaire plutôt que criminelle, tant que l’infraction reprochée demeure collée aux faits. Dans tous les cas, l’entente devra être approuvée par un juge si celui-ci la juge raisonnable.</p>
<p><strong>Engagez un avocat criminaliste spécialisé! </strong>Le droit criminel est une branche du droit qui est extrêmement spécialisée. La plupart des avocats qui y pratiquent consacrent l’entièreté de leur pratique à ce domaine précisément pour cette raison.  Si vous désirez mettre toutes les chances de votre côté de faire tomber ces accusations, l’intervention d’un avocat criminaliste est indispensable.</p><p></p>
<p></p>
<h2>Défendez-vous contre une accusation de possession ou trafic de drogues avec un avocat compétent!</h2>
<p>Vous ne souhaitez pas passer les prochaines années derrière les barreaux? Recevoir une amende de plusieurs milliers de dollars ne vous intéresse pas plus qu’il faut? Ça se comprend, mais ce que VOUS devez comprendre, c’est que la seule façon d’éviter ces scénarios peu enviables est d’engager un avocat spécialisé en droit criminel dès maintenant.</p>
<p><strong>Soumissions Avocat a la solution qu’il vous faut! Nous disposons d’un réseau d’avocats criminalistes présent partout au Québec et prêt à vous venir en aide. Il suffit de remplir notre formulaire de demande de soumission pour être mis en contact avec un avocat criminaliste.</strong></p>
<p><strong>Nos services de mise en contact sont complètement gratuits et sans engagement, alors contactez-nous et mettez toutes les chances de votre côté!</strong></p>
    </SeoPage>
)
export default BlogPost7
  